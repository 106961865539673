/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import axios from "axios";

const getRowsRoute = window.REACT_APP_GETROWS_ROUTE;
const MGURL = window.REACT_APP_BFF_MG_URL;
const lt = /</g;
const gt = />/g;
const ap = /'/g;

export async function search(
  e,
  forms,
  setformNumberAndName,
  setTable,
  filterLanguage
) {
  setformNumberAndName(e);
  const tempTable = [];
  forms.map(record => {
    if (
      record.FormNumberAndName.toUpperCase().includes(e.toUpperCase()) &&
      record.Language.includes(filterLanguage)
    ) {
      tempTable.push(record);
    }
  });
  setTable(tempTable);
}

export const getRoleData = (formnumber, language) => {
  return axios
    .post(getRowsRoute, {
      data: {
        FormNumber: formnumber,
        Language: language
      }
    })
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
};

export const sanitizeVal = imageStr => {
  return imageStr
    .toString()
    .replace(lt, "&lt;")
    .replace(gt, "&gt;")
    .replace(ap, "&#39;");
};

export const imageObjects = (docImages, tableData, language, Card, colors) => {
  const divStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "left",
    marginBottom: "2%",
    borderColor: "#EDEDED",
    borderStyle: "solid",
    borderWidth: "1px"
  };
  const cardStyle = {
    width: "100%",
    marginBottom: "2%"
  };
  const suppStyle = {
    fontSize: "0.90rem"
  };
  const documentImages = Object.values(docImages);
  for (const docs of documentImages) {
    if (docs.display === "inline" && docs.imageBytes !== null) {
      for (const data of docs.imageBytes) {
        if (typeof data === "string") {
          // sanitize input for DOMXSS protection snyk code scan
          const sanitizedVal = sanitizeVal(data);
          tableData.push(
            <div style={divStyle}>
              <img
                src={`data:image/png;base64, ${sanitizedVal}`}
                alt={language.PreviewForm}
              />
            </div>
          );
        } else {
          tableData.push("No Preview Available");
        }
      }
    } else if (docs.display === "modal" && docs.imageBytes.length === 0) {
      const styles = {
        color: colors.utility_info
      };
      tableData.push(
        <Card cardStyle={cardStyle}>
          <Card.Header withAdornment fontSize={Card.FONT_SIZE.SMALL}>
            <span style={styles}>{docs.name}</span>
          </Card.Header>
          <Card.Content>
            <span style={suppStyle}>{language.supplementalMsg}</span>
          </Card.Content>
        </Card>
      );
    }
  }
};

export const imageArray = (docImages, tableData, language) => {
  for (const data of docImages) {
    if (typeof data === "string") {
      // sanitize input for DOMXSS protection snyk code scan
      const sanitizedVal = sanitizeVal(data);
      tableData.push(
        <img
          src={`data:image/png;base64, ${sanitizedVal}`}
          alt={language.PreviewForm}
        />
      );
    } else {
      tableData.push("No Preview Available");
    }
  }
};

export const imageString = (documentImages, tableData, language) => {
  // sanitize input for DOMXSS protection snyk code scan
  const sanitizedVal = sanitizeVal(documentImages);
  tableData.push(
    <img
      src={`data:image/png;base64, ${sanitizedVal}`}
      alt={language.PreviewForm}
    />
  );
};
