/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TextInput,
  Button,
  H1,
  H4,
  P,
  Tooltip,
  TextLink,
  useLang
} from "@manulife/mux";
import { Link } from "react-router-dom";
import { submitPrefill, getRoles } from "../actions/signerTableActions";
import { redirectUnauthenticated } from "../actions/CIAMActions";
import {
  populateSignerRows,
  populateCopyRows,
  validation
} from "../methods/signersPageMethods";
import localizationObject from "../localization";
import { storeURL } from "../actions/confirmationActions";

import "../App.css";

function SignerTable() {
  const dispatch = useDispatch();
  const boundSubmitPrefill = (prefillSigners, policyNumber) =>
    dispatch(submitPrefill(prefillSigners, policyNumber));
  const boundGetRoles = (formNumber, language) => {
    dispatch(getRoles(formNumber, language));
  };
  const roles = useSelector(state => state.roles.signerRoles);
  const DEFAULT_NUMBER_OF_SIGNERS = (roles && roles.length) || 10;
  const DEFAULT_NUMBER_OF_SIGNERS_COPY = 10;
  const advisorPrefill = useSelector(state => state.roles.advisorPrefill);
  const carbonCopies = useSelector(state => state.roles.carbonCopies);
  const advisorSigning = useSelector(state => state.envelope.advisorSigning);
  const isCIAM = useSelector(state => state.pageLoad.ciamSession);

  const defaultAccessCode = useSelector(state => state.pageLoad.accessCode);
  const [accessCode, setAccessCode] = useState(defaultAccessCode.toString());

  // Signers Table
  const [rows, setRows] = useState([]);
  const [partyFirstNames, setPartyFirstNames] = useState(
    new Array(DEFAULT_NUMBER_OF_SIGNERS).fill("")
  );
  const [partyLastNames, setPartyLastNames] = useState(
    new Array(DEFAULT_NUMBER_OF_SIGNERS).fill("")
  );
  const [emails, setEmails] = useState(
    new Array(DEFAULT_NUMBER_OF_SIGNERS).fill("")
  );
  const [signerToggles, setSignerToggles] = useState(
    new Array(DEFAULT_NUMBER_OF_SIGNERS).fill("")
  );
  const [toggleStates, setToggleStates] = useState(
    new Array(DEFAULT_NUMBER_OF_SIGNERS).fill("")
  );

  // Carbon Copy Table
  const [copyRows, setCopyRows] = useState([]);
  const [copyFirstNames, setCopyFirstNames] = useState(
    new Array(DEFAULT_NUMBER_OF_SIGNERS_COPY).fill("")
  );
  const [copyLastNames, setCopyLastNames] = useState(
    new Array(DEFAULT_NUMBER_OF_SIGNERS_COPY).fill("")
  );
  const [copyEmails, setCopyEmails] = useState(
    new Array(DEFAULT_NUMBER_OF_SIGNERS_COPY).fill("")
  );

  const [error, setError] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const tempPageLoadState = useSelector(state => state.pageLoad);
  const formInfo = useSelector(state => state.pageLoad.routeState);

  const [language, setLanguage] = useState({});
  const lang = useLang();
  const [myClientsURL, setMyClientsUrl] = useState("");
  const myClientEnglish = window.REACT_APP_MY_CLIENTS_LINK_EN;
  const myClientFrench = window.REACT_APP_MY_CLIENTS_LINK_FR;
  const reactPath = window.REACT_APP_PUBLIC_URL;

  // translation of french/english using LANG PROVIDER
  useEffect(() => {
    const langVal = lang.length > 0 ? lang : "en-CA";
    setLanguage(localizationObject[langVal]);
    const myclients = langVal === "en-CA" ? myClientEnglish : myClientFrench;
    setMyClientsUrl(myclients);
  }, [lang]);

  useEffect(() => {
    if (roles && roles.length !== 0 && emails.length !== roles.length) {
      setEmails(new Array(DEFAULT_NUMBER_OF_SIGNERS).fill(""));
      setPartyFirstNames(new Array(DEFAULT_NUMBER_OF_SIGNERS).fill(""));
      setPartyLastNames(new Array(DEFAULT_NUMBER_OF_SIGNERS).fill(""));
      setSignerToggles(new Array(DEFAULT_NUMBER_OF_SIGNERS).fill(""));
      setToggleStates(new Array(DEFAULT_NUMBER_OF_SIGNERS).fill(""));
    }
  }, [roles]);

  useEffect(() => {
    // redirect to login page if not logged in
    redirectUnauthenticated(formInfo);
    boundGetRoles(formInfo.formnumber, formInfo.formLanguage);
    // reset store on page visit
    boundSubmitPrefill([], "");
    dispatch(storeURL(""));
  }, []);

  window.onbeforeunload = () => {
    const currPath = window.location.pathname;
    return currPath === `${reactPath}/Signers` ? true : null;
  };

  useEffect(() => {
    const tempRows = populateSignerRows(
      roles,
      partyFirstNames,
      partyLastNames,
      emails,
      setPartyFirstNames,
      setPartyLastNames,
      setEmails,
      signerToggles,
      toggleStates,
      setSignerToggles,
      setToggleStates,
      language
    );

    const tempCopyRows = populateCopyRows(
      carbonCopies,
      copyFirstNames,
      copyLastNames,
      copyEmails,
      setCopyFirstNames,
      setCopyLastNames,
      setCopyEmails,
      language
    );
    setRows(tempRows);
    setCopyRows(tempCopyRows);
  }, [
    emails,
    language,
    partyFirstNames,
    partyLastNames,
    copyEmails,
    copyFirstNames,
    copyLastNames,
    roles,
    roles.length,
    signerToggles,
    toggleStates
  ]);

  const columnWidth = "18%";
  const tableWidth = "100%";
  const commonHeader = () => {
    return (
      <>
        <th width={{ columnWidth }}>
          <H4>{language.RoleLabel}</H4>
        </th>
        <th width={{ columnWidth }}>
          <H4>{language.FirstName}</H4>
        </th>
        <th width={{ columnWidth }}>
          <H4>{language.LastName}</H4>
        </th>
        <th width={{ columnWidth }}>
          <H4>{language.EmailLabel}</H4>
        </th>
      </>
    );
  };

  return isCIAM && language.Signers ? (
    <div className="page">
      <div className="padding">
        <div tabIndex="0">
          <H1>{language.Signers}</H1>
        </div>
        <P>
          {language.SigningInstructions1}
          <TextLink to={myClientsURL} isExternal>
            {language.MyClient}
          </TextLink>
          {language.SigningInstructions1a}
        </P>
        <P>{language.SigningInstructions2}</P>
        <P>{language.SigningInstructions3}</P>
        <P>
          <strong>{language.SigningReminder}</strong>
          {language.SigningInstructions4}
        </P>
        <label className="m1" htmlFor="Policy Number">
          {language.PolicyNumber}
          <Tooltip
            title={language.PolicyNumberTooltip}
            variant="secondary"
            ariaLabel={language.PolicyNumberTooltip}
            customStyle={{
              rootStyle: {
                marginLeft: "3%",
                marginBottom: "3%"
              }
            }}
          />
          <br />
          <TextInput
            placeholder={language.PolicyNumber}
            ariaLabel={language.PolicyNumber}
            id="policyNumberInput"
            onChange={e => {
              setPolicyNumber(e);
            }}
            value={policyNumber}
            mask="***************"
            forceUpper
            customStyle={{
              rootStyle: {
                maxWidth: "70%"
              }
            }}
          />
        </label>
        <br />
        <label className="m1" htmlFor="Email Access code">
          {language.EmailAccessCode}
          <Tooltip
            title={language.EmailAccessCodeTooltip}
            variant="secondary"
            ariaLabel={language.EmailAccessCodeTooltip}
            customStyle={{
              rootStyle: {
                marginLeft: "3%",
                marginBottom: "3%"
              }
            }}
          />
          <br />
          <TextInput
            placeholder={accessCode}
            ariaLabel={language.EmailAccessCode}
            maxLength={10}
            onChange={e => {
              setAccessCode(e);
            }}
            value={accessCode}
            customStyle={{
              rootStyle: {
                maxWidth: "70%"
              }
            }}
          />
        </label>
        <div aria-live="assertive">
          <P color="red" data-testid="error">
            {error}
          </P>
        </div>
      </div>
      <div className="table">
        <table width={tableWidth} className="nativeTable">
          <thead>
            <tr>
              {commonHeader()}
              <th width="18%">
                <H4>{language.SigningMethodLabel}</H4>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 ? (
              rows.map((row, _i) => {
                return (
                  <tr>
                    <td>{row.role}</td>
                    <td>{row.partyFirstName}</td>
                    <td>{row.partyLastName}</td>
                    <td>{row.email}</td>
                    <td>{row.signMethod}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="5">{language.NoRowsText}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="padding">
        <P>{language.CarbonCopy}</P>
      </div>
      <div className="table">
        <table width={tableWidth} className="nativeTable">
          <thead>
            <tr>{commonHeader()}</tr>
          </thead>
          <tbody>
            {copyRows.length > 0 ? (
              copyRows.map((row, _i) => {
                return (
                  <tr>
                    <td>{row.role}</td>
                    <td>{row.copyFirstName}</td>
                    <td>{row.copyLastName}</td>
                    <td>{row.copyEmail}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="4">{language.NoRowsText}</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="center">
          <Link
            to="/Confirmation"
            onClick={e =>
              validation(
                e,
                accessCode,
                emails,
                partyFirstNames,
                partyLastNames,
                policyNumber,
                copyEmails,
                copyFirstNames,
                copyLastNames,
                setError,
                language,
                advisorPrefill,
                tempPageLoadState,
                signerToggles,
                carbonCopies,
                roles,
                boundSubmitPrefill,
                advisorSigning
              )
            }
          >
            <Button id="signerSubmit" ariaLabel={language.Next}>
              {language.Next}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  ) : null;
}

export default SignerTable;
