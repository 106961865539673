/* eslint-disable react/function-component-definition */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from "react";
import { Provider, useSelector } from "react-redux";
import { LANG, MuxProvider, THEMES } from "@manulife/mux";
import Routes from "./components/Routes";
import store from "./store";

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

function App() {
  const langVal = localStorage.getItem("langval");
  const language = useSelector(state => state.pageLoad.language);
  const [localization, setLocalization] = useState(langVal);
  useEffect(() => {
    setLocalization(langVal);
  }, [language]);
  return (
    <MuxProvider
      themeProvider={{ theme: THEMES.canadianTheme }}
      langProvider={{ lang: localization }}
    >
      <Routes />
    </MuxProvider>
  );
}

export default AppWrapper;
