/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import {
  SESSION_INFO_CIAM,
  REFRESH_SESSION_CIAM,
  REFRESH_SESSION_CIAM_PROGRESS,
  LOGOUT_SESSION_PROGRESS
} from "./types";
import { generatePass, setAxiosHeaders } from "../methods/eSignatureMethods";
import Language from "../localization";

const redirectURL = window.REACT_APP_CIAMBFF_URL;
const OK_RESPONSE = 200;
const langValName = "langval";

const saveLangVal = async (langVal) => {
  if (localStorage.getItem(langValName) !== langVal) {
    localStorage.setItem(langValName, langVal);
  }
}

const removeLangVal = async () => {
  localStorage.removeItem(langValName);
}

export const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}

export const redirectUnauthenticated = (formInfo) => {
  if (isObjectEmpty(formInfo)) {
    window.location.href = `${redirectURL}?formnumber=all`;
  }
}

export const bffSessionInfo = (sessionInfo) => async (dispatch) => {
  setAxiosHeaders(sessionInfo.esigApiToken);
  let formnumber = "";

  if (sessionInfo.formnumber) {
    formnumber = sessionInfo.formnumber;
  }

  let language = sessionInfo.preferredlanguage === "French" ? "fr" : "en";

  if (sessionInfo.language !== "") {
    language = sessionInfo.language === "fr-CA" ? "fr" : "en";
  }
  console.log(`preferred language AP : ${language}`);
  const langVal = language === "fr" ? 'fr-CA' : 'en-CA';
  sessionInfo.strings = Language[langVal];

  await saveLangVal(langVal);

  const routeState = {
    location: "./",
    formnumber,
    token: sessionInfo.esigApiToken
  };

  sessionInfo.preferredlanguage = language;
  sessionInfo.routeState = routeState;
  sessionInfo.accessCode = await generatePass();

  dispatch({
    type: SESSION_INFO_CIAM,
    sessionInfo
  });
};

export const bffRefreshSession = () => async (dispatch) => {
  try {
    const response = await fetch(`${redirectURL}/refreshSession`, {
      method: "GET",
      credentials: "include"
    });
    if (response.status === OK_RESPONSE) {
      dispatch({
        type: REFRESH_SESSION_CIAM,
        data: Date.now()
      });
    }
    const refSessionData = await response.json();
    if (refSessionData.esigApiToken != null) {
      setAxiosHeaders(refSessionData.esigApiToken);
    }
    return {
      status: response.status,
      data: refSessionData
    };
  } catch (e) {
    return false;
  }
};

/**
 *
 * Redirect to /ciam/?formnumber={formNumber}&logout
 * set the logout=true as parameter
 * this should call the ciam/userlogout route from ciam
 * Set the LOGOUT_SESSION_PROGRESS to true
 *
 *
 * @param {string} formNumber
 * @returns
 */
export const bffLogoutSession = (formNumber) => async (dispatch) => {
  formNumber = formNumber === undefined ? "" : formNumber;
  await removeLangVal();

  dispatch({
    type: LOGOUT_SESSION_PROGRESS
  });
  window.location.assign(`
    ${redirectURL}/?formnumber=${formNumber}&logout=true`);
};

export const bffRefreshSessionInProgress = () => async (dispatch) => {
  dispatch({
    type: REFRESH_SESSION_CIAM_PROGRESS
  });
};

export const bffChangeLang = (language, formNumber) => async () => {
  formNumber = formNumber === undefined ? "" : formNumber;
  await saveLangVal(language);
  const redirUrl = `${redirectURL}/?formnumber=${formNumber}&language=${language}`
  window.location.assign(redirUrl);
}
