/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, H2, P, useLang } from "@manulife/mux";
import Iframe from "react-iframe";
import ClipLoader from "react-spinners/ClipLoader";
import { insertCustomer, storeURL } from "../actions/confirmationActions";
import {
  checkSigners,
  createEnvelope,
  getRecipients
} from "../methods/confirmationPageMethods";
import { getDocumentURL, getDocumentStatus } from "../methods/eSignatureMethods";
import doneImage from "../img/done.jpg";
import { loadInfo, storeLanguage } from "../actions/formSelectActions";
import { redirectUnauthenticated } from "../actions/CIAMActions";
import localizationObject from "../localization";

function Confirmation() {
  const dispatch = useDispatch();
  const boundInsertCustomer = (
    tempEnvelopeState,
    tempPageLoadState,
    tempRolesState,
    ownerFirstName,
    ownerLastName,
    envelopeID,
    accessCode
  ) =>
    dispatch(
      insertCustomer(
        tempEnvelopeState,
        tempPageLoadState,
        tempRolesState,
        ownerFirstName,
        ownerLastName,
        envelopeID,
        accessCode
      )
    );
  const reactPath = window.REACT_APP_PUBLIC_URL;
  const boundStoreURL = docuSignURL => dispatch(storeURL(docuSignURL));
  const boundStoreLanguage = strings => dispatch(storeLanguage(strings));
  const boundLoadInfo = routeState => dispatch(loadInfo(routeState));
  const tempEnvelopeState = useSelector(state => state.envelope);
  const tempPageLoadState = useSelector(state => state.pageLoad);
  const tempRolesState = useSelector(state => state.roles);
  const signers = useSelector(state => state.envelope.prefillSigners);
  const advisorSigning = useSelector(state => state.envelope.advisorSigning);
  const advisorToggle = useSelector(state => state.envelope.advisorToggle);
  const buttonDisable = useSelector(state => state.envelope.disabled);
  const [accessCode, setAccessCode] = useState("");
  const [hasAccessCode, setHasAccessCode] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showDone, setShowDone] = useState(false);
  const [prefillSigning, setPrefillSigning] = useState(true);
  const [counter, setCounter] = useState(0);
  const [nextSignerText, setNextSignerText] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [SRDoneSigningText, setSRDoneSigningText] = useState("");
  const [language, setLanguage] = useState({});
  const [iframeLang, setiframeLang] = useState("");
  const [iframeID, setiframeID] = useState("iframeId");
  const [iframeControl, setiframeControl] = useState(false);
  const [iframeStatus, setIframeStatus] = useState(true);
  const [btnDisabledOnLoad, setbtnDisabledOnLoad] = useState(false);
  const [btnTxt, setbtnTxt] = useState("");
  const [showLoading, setshowLoading] = useState(false);
  const [declinedSigning, setDeclinedSigning] = useState(true);
  const lang = useLang();
  const formInfo = useSelector(state => state.pageLoad.routeState);
  const isCIAM = useSelector(state => state.pageLoad.ciamSession);
  // translation of french/english using LANG PROVIDER
  useEffect(() => {
    if (lang.length > 0) {
      setLanguage(localizationObject[lang]);
    } else {
      setLanguage(localizationObject["en-CA"]);
    }
    if(tempEnvelopeState.docuSignURL !== ""){
      const formLang = formInfo.formLanguage === "FR" ? "fr_CA":"en";
      const iframeLangVal = `${tempEnvelopeState.docuSignURL}&locale=${formLang}`;
      setiframeLang(iframeLangVal);
    }
  }, [lang,tempEnvelopeState]);

  const launchAdvisorViewText = language.LaunchAdvisorView
    ? language.LaunchAdvisorView
    : "Launch Advisor View";
  const [envelopeCreated, setEnvelopeCreated] = useState(false);

  useEffect(() => {
    setDisableNext(true);
    if (envelopeCreated) {
      nextSigner();
    }
  }, [envelopeCreated]);

  useEffect(() => {
    setAccessCode(
      signers.find(item => {
        return item.accessCode;
      })
    );
  }, [accessCode]);

  useEffect(() => {
    if (signers.length > 0 && !envelopeCreated) {
      createEnvelope(
        signers,
        tempEnvelopeState,
        tempPageLoadState,
        tempRolesState,
        localizationObject[language],
        setEnvelopeCreated,
        setShowForm,
        setError,
        setLoading,
        boundStoreURL,
        boundInsertCustomer
      );
      setEnvelopeCreated(true);
    }
    if (advisorToggle) {
      setShowDone(true);
      setNextSignerText(false);
    }
  }, [tempEnvelopeState, tempPageLoadState, tempRolesState]);

  function checkEnvStatus(){
  const data = {
      DocuSignAccountNumber: tempRolesState.accountId,
      EnvelopeID: tempEnvelopeState.envelopeId,
  }
  if(counter === signers.length){
    setshowLoading(true);
  }
  if(hasAccessCode)
  {
    getRecipients(data.DocuSignAccountNumber,data.EnvelopeID).then(response => {
      if(response[0].recipientId === "1" && response[0].status === "completed")
      {
        setiframeControl(true);
        nextSigner(); // automaticallly show done image
      }
      else if(response[0].recipientId === "1" && response[0].status === "declined")
        {
          setiframeControl(true); // when user declines then check envstatus and hide iframe
          setDeclinedSigning(false);
        }
    });
  }else{
    getDocumentStatus(data,"docname").then(response => {
      if(response.status ==="completed")
      {
        setiframeControl(true);
        nextSigner(); // automaticallly show done image
      }
      if(response.status ==="declined")
        {
          setiframeControl(true); // when user declines then check envstatus and hide iframe
          setDeclinedSigning(false);
        }
    });
  }
  }
  const iFrameChange = () => {
    const iFrameSrc = document.getElementById(iframeID).src;
    if (iFrameSrc !== iframeLang) {
      return;
    }
    const newIframeStatus = iframeStatus !== true;
    setIframeStatus(newIframeStatus);
    setshowLoading(false);
    console.log(`New iframe status ${newIframeStatus}`);
    // when at the end dont re enable button
    const val = counter === signers.length ? true:!btnDisabledOnLoad;
    setbtnDisabledOnLoad(val);
    setDisableNext(val);
    if(iframeControl === false && envelopeCreated){
      if(newIframeStatus){
        console.log(`checked EnvStatus`);
        checkEnvStatus();
      }
    }
  }

  useEffect(() => {
    // redirect to login page if not logged in
    redirectUnauthenticated(formInfo);
    const txt  = !showForm && nextSignerText === "" ? launchAdvisorViewText:nextSignerText;
    const toShowTxt = showLoading ? language.btnLoadingTxt:txt;
    setbtnTxt(toShowTxt);
  }, [showForm,nextSignerText,launchAdvisorViewText,showLoading]);

  const nextSigner = async () => {
    let signerError = "";
    if (counter > 0) {
      signerError = await checkSigners(
        counter,
        tempEnvelopeState,
        tempPageLoadState,
        tempRolesState,
        language
      );
      setError(signerError);
    }
    if (signerError.length > 0) {
      return;
    }

    let tempCounter = counter;
    setCounter((tempCounter += 1));
    // change iframeID
    setiframeID(`iframeId_${counter}`);
    if (nextSignerText === language.Done) {
      setShowDone(true);
      setSRDoneSigningText(language.Finished);
    }

    const inPersonSigners = signers.filter(
      item =>
        !item.hasOwnProperty("accessCode") && !item.roleName.includes("copy")
    );

    if (signers.length > tempCounter) {
      if (signers[tempCounter].hasOwnProperty("accessCode")) {
        setHasAccessCode(true);
      } else {
        setHasAccessCode(false);
      }
    }

    if (counter === 0) {
      if (
        counter === inPersonSigners.length - 1 ||
        inPersonSigners.length === 0
      ) {
        setNextSignerText(language.Done);
      } else {
        setNextSignerText(language.LaunchClientView);
      }
      if (error.length === 0) {
        setShowForm(true);
      }
    } else if (counter < inPersonSigners.length) {
      setNextSignerText(language.Next);

      setDisableNext(true);
      if (signers[counter].roleName === "Advisor Optional Prefill") {
        setPrefillSigning(true);
      } else {
        setPrefillSigning(false);
      }

      getDocumentURL(
        tempEnvelopeState.envelopeId,
        signers[counter],
        tempRolesState.accountId
      ).then(docuSignURL => {
        if (docuSignURL.toString().includes("Error")) {
          setError(language.InvalidNextError);
          setDisableNext(false);
        } else {
          setError("");
          boundStoreURL(docuSignURL);
          setDisableNext(false);
        }
      });
      if (counter === inPersonSigners.length - 1) {
        setNextSignerText(language.Done);
      }
    } else {
      setShowForm(false);
    }
  };

  return isCIAM && language.PleaseWait ? (
    <div className="page center">
      {
        // Finished Text when signing is done
      }
      <p className="sr-only" aria-live="polite">
        {SRDoneSigningText}
      </p>
      {
        // instructions
      }
      {!showForm && !showDone && tempEnvelopeState.passed ? (
        <p hidden={iframeControl && !declinedSigning}>{language.InPersonInstructions1}</p>
      ) : null}
      {
        // instructions
      }
      {showForm &&
      tempEnvelopeState.passed &&
      prefillSigning &&
      !hasAccessCode &&
      signers.every(
        item => item.roleName !== "Advisor" && item.roleName !== "Conseiller"
      ) ? (
        <span hidden={iframeControl && !declinedSigning}>        
            <P aria-live="polite">{language.InPersonInstructions2}</P>
            <P>{language.InPersonInstructions3}</P>
        </span>
      ) : null}
      {
        // instructions
      }
      {showForm &&
      tempEnvelopeState.passed &&
      prefillSigning &&
      hasAccessCode &&
      signers.every(
        item => item.roleName !== "Advisor" && item.roleName !== "Conseiller"
      ) ? (
        <span hidden={iframeControl && !declinedSigning}>
          <P aria-live="polite">{language.InPersonInstructions2}</P>
        </span>
      ) : null}
      {
        // instructions
      }
      {showForm &&
      tempEnvelopeState.passed &&
      (signers.every(
        item => item.roleName === "Advisor" || item.roleName === "Conseiller"
      ) ||
        !prefillSigning) ? (
        <span hidden={iframeControl && !declinedSigning}>
          <P aria-live="polite">{language.InPersonSignerInstructions}</P>
        </span>
      ) : null}
      {
        // instructions
      }
      {accessCode && showDone && signers.length > 1 ? (
        <span autoFocus="true" hidden={iframeControl && !declinedSigning}>
          <P aria-live="polite">{language.BothInstructions}</P>
        </span>
      ) : null}
      {
        // instructions
      }
      {accessCode ? (
        <span hidden={iframeControl && !declinedSigning}>
          <H2 aria-live="polite">
            <strong>
              <em>
                {language.AccessCode}
                {accessCode.accessCode}
              </em>
            </strong>
          </H2>
          <P>{language.AccessCodeInstructions1}</P>
          <P>{language.AccessCodeInstructions2}</P>
          <P>{language.EmailInstructions}</P>
        </span>
      ) : null}
            {
        // error message when didnt finish eform
      }
      <span aria-live="assertive" hidden={iframeControl && !declinedSigning}>
        <P color="red" data-testid="error">
          {error}
        </P>
      </span>
      {
        // Launch Clientview / Done Button
      }
      {showForm ? (
        <span hidden={iframeControl && !declinedSigning}>
          <Button
            className="margin"
            ariaLabel={
              !showForm && nextSignerText === ""
                ? launchAdvisorViewText
                : nextSignerText
            }
            disabled={!tempEnvelopeState.passed || showDone || disableNext || iframeControl || btnDisabledOnLoad}
            onClick={() => nextSigner()}
            id="nextbtn"
          >
            {btnTxt}
          </Button>
        </span>
      ) : null}
      {
        // iframe for docusign // declined txt & button
      }
      {showForm ? (
      <div>
        <span hidden={iframeControl}>
          <Iframe
            key={counter}
            url={iframeLang}
            id={iframeID}
            width="95%"
            height="550px"
            sandbox={[
              "allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts allow-downloads"
            ]}
            onLoad={()=> iFrameChange()}
          />
        </span>
        <span autoFocus="true" hidden={declinedSigning}>
          <P aria-live="polite">{language.declinedSignTxt}</P>
            <Link to="/">
            <Button
              className="margin"
              id="goBack"
              onClick={() => {
                boundLoadInfo({
                  ...formInfo,
                  formnumber: "all",
                });
              }}
            >
            {language.declinedSignBtnTxt}
            </Button>
            </Link>
        </span>
      </div>
      ) : null}
      {
        // Done signing Image
      }
      {showDone && error === "" && buttonDisable === true ? (
        <div>
          {advisorSigning ? " " : <P>{language.Finished}</P>}
          <img
            src={doneImage}
            alt="Done signing"
            data-testid="doneImage"
            aria-label="Done signing"
          />
        </div>
      ) : null}
      {
        // Loading Image
      }
      {!showForm && !showDone ? (
        <div>
          <ClipLoader size={36} color="#123abc" loading={loading} />
        </div>
      ) : null}
      
    </div>
  ) : null;
}

export default Confirmation;